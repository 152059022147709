import { Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const StyledHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  maxWidth: '1440px',
  position: 'fixed',
  zIndex: 10,
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'space-between',
  top: '12px',
  left: 0,
  right: 0,
  margin: 'auto',
  // transition: '.2s',
  // height: '56px',
  // borderRadius: '32px',
  width: 'calc(100% - 18px)',
  // overflow: 'hidden',
  // blur寫在div本身會造成內容一起模糊, 所以寫在before內, 但absolute的關係可能造成padding無效內容高度超出面板
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '32px',
    backgroundColor: alpha(theme.palette.grey[400], 0.8),
    backdropFilter: 'blur(4px)',
    width: '100%',
    height: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    '&:before': {
      borderRadius: '36px',
    },
  },
  [theme.breakpoints.up('lg')]: {
    justifyContent: 'flex-start',
    maxWidth: 'calc(1440px - 160px)', // margin 80*2
  },
}));

const StyledBar = styled(Box)(({ theme }) => ({
  padding: '8px 12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    padding: '12px 14px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '15px 22px',
  },
}));

const StyledMenuContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  height: active ? 'calc(100vh - 200px)' : '0',
  display: 'flex',
  flexDirection: 'column',
  transition: '.2s',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    width: '100%',
    height: active ? '265px' : '0',
    alignItems: 'flex-start',
    // gap: 70px;
  },
}));

const StyledHeaderLogo = styled(Box)(({ theme }) => ({
  margin: 0,
  position: 'relative',
  a: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  img: {
    verticalAlign: 'middle',
  },
  [theme.breakpoints.up('lg')]: {
    width: '20%',
  },
}));
const StyledMenuWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  gap: '180px',
  position: 'relative',
}));

const StyledMenuChildrenWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  height: '100%',
  marginLeft: '272px',
  '& > div:nth-of-type(1)': {
    width: '205px',
  },
  '& > div:nth-of-type(2)': {
    marginLeft: '54px',
    width: '108px',
  },
  '& > div:nth-of-type(3)': {
    marginLeft: '151px',
    width: '144px',
  },
  '& > div:nth-of-type(4)': {
    marginLeft: '116px',
    width: '150px',
  },
}));

const StyledSingleChildrenGroup = styled(Box)(({ theme }) => ({
  height: '100%',
  width: 'fit-content',
}));

const StyledChildrenItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isTextBreak',
})<{ isTextBreak?: boolean }>(({ theme, isTextBreak }) => ({
  fontSize: '18px',
  fontWeight: 700,
  color: theme.palette.grey[900],
  a: {
    color: 'currentColor',
    textDecoration: 'none',
    transition: '.2s',
    whiteSpace: isTextBreak ? 'break-spaces' : 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  marginTop: isTextBreak ? '12px' : 0,
  '& + &': {
    marginTop: '12px',
  },
}));

const StyledMenuTitle = styled('h2')(({ theme }) => ({
  margin: 0,
  fontSize: '20px',
  fontWeight: 700,
  color: theme.palette.secondary.dark,
}));

// mobile
const StyledMobileMenuWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // padding: '12px',
}));

const StyledMobileMenuTitle = styled('h2')(({ theme }) => ({
  margin: 0,
  fontSize: '16px',
  fontWeight: 700,
  color: theme.palette.grey.A100,
}));

const StyledAccordionWrapper = styled(Box)(({ theme }) => ({
  '& .MuiPaper-root': {
    background: 'transparent',
    border: 0,
    boxShadow: 'none',
  },
  '& .MuiAccordionSummary-root': {
    border: 0,
    boxShadow: 'none',
    padding: '0 12px',
    minHeight: '30px',
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  '& .MuiCollapse-root': {
    backgroundColor: alpha(theme.palette.grey.A200, 0.4),
    padding: '0 12px',
  },
  '& .MuiAccordionDetails-root': {
    padding: '10px 0',
    margin: 0,
  },
  '& + &': {
    margin: '10px 0 0 0',
  },
}));

const StyledMobileMenuItem = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 700,
  color: theme.palette.grey[900],
  a: {
    color: 'currentColor',
    textDecoration: 'none',
    transition: '.2s',
    // '&:hover': {
    //   color: theme.palette.primary.main,
    //   textDecoration: 'underline',
    // }
  },
  '& + &': {
    marginTop: '16px',
  },
}));

export {
  StyledHeader,
  StyledHeaderLogo,
  StyledBar,
  StyledMenuContent,
  StyledMenuWrapper,
  StyledMenuChildrenWrapper,
  StyledSingleChildrenGroup,
  StyledChildrenItem,
  StyledMenuTitle,
  StyledMobileMenuWrapper,
  StyledMobileMenuTitle,
  StyledAccordionWrapper,
  StyledMobileMenuItem,
};

import React, { useState } from 'react';
import {
  StyledGroup,
  StyledWrapper,
  StyledUnHover,
  StyledHover,
} from './styles';
import { ButtonStyleType } from '../../../types/button';
import { SxProps } from '@mui/material';

type Props = {
  styleType: ButtonStyleType;
  icon: React.ReactNode;
  active?: boolean;
  sx?: SxProps;
};
const SlideUpIcon = ({ styleType, icon, active, sx }: Props) => {
  return (
    <StyledWrapper
      sx={sx}
      styleType={styleType}
      className={active ? 'active' : ''}
    >
      <StyledGroup className="animation">
        <StyledUnHover>{icon}</StyledUnHover>
        <StyledHover styleType={styleType} className="hover">
          {icon}
        </StyledHover>
      </StyledGroup>
    </StyledWrapper>
  );
};

export default SlideUpIcon;

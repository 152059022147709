import { alpha, createTheme } from '@mui/material';
import {
  GREY100,
  GREY200,
  GREY300,
  GREY400,
  GREY500,
  GREY600,
  GREY700,
  GREY800,
  GREY900,
  GREYA100,
  GREYA200,
  GREYA400,
  PRIMARY_DARK,
  PRIMARY_LIGHT,
  PRIMARY_MAIN,
  SECONDARY_MAIN,
  SECONDARY_DARK,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  GREYA700,
  SECONDARY_LIGHT,
  PRIMARY_BASIC,
} from './colors';

declare module '@mui/material/styles' {
  interface SimplePaletteColorOptions {
    basic?: string;
  }
  interface PaletteColor {
    basic: string;
  }
  interface Palette {
    default: PaletteColor;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // 0-640 mobile
      sm: 640, // 640-1270 pad
      md: 1270, // pc
      lg: 1440,
      xl: 1920,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '20px !important',
          background: GREY200,
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 18px',
          '&.Mui-expanded:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: '20px',
            right: '20px',
            bottom: 0,
            height: '1px',
            background: alpha(GREY800, 0.3),
          },
        },
        content: {
          margin: '14px 0',
          fontSize: '18px',
          fontWeight: 700,
          color: PRIMARY_MAIN,
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        },
        expandIconWrapper: {
          '&.Mui-expanded': {
            transform: 'rotate(135deg)',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '12px 18px 24px 18px',
          fontSize: '16px',
          fontWeight: 400,
        },
      },
    },
  },
  typography: {
    fontFamily: ['Noto Sans TC', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: PRIMARY_MAIN,
      light: PRIMARY_LIGHT,
      dark: PRIMARY_DARK,
      basic: PRIMARY_BASIC,
    },
    secondary: {
      main: SECONDARY_MAIN,
      dark: SECONDARY_DARK,
      light: SECONDARY_LIGHT,
    },
    text: {
      primary: TEXT_PRIMARY,
      secondary: TEXT_SECONDARY,
    },
    grey: {
      '100': GREY100,
      '200': GREY200,
      '300': GREY300,
      '400': GREY400,
      '500': GREY500,
      '600': GREY600,
      '700': GREY700,
      '800': GREY800,
      '900': GREY900,
      A100: GREYA100,
      A200: GREYA200,
      A400: GREYA400,
      A700: GREYA700,
    },
  },
});

export default theme;

import { Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { ButtonStyleType } from '../../../types/button';

const StyledWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'styleType',
})<{ styleType?: ButtonStyleType }>(({ theme, styleType }) => ({
  width: 'fit-content',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '50%',
  border: `2px solid ${
    styleType === 'blueOutline'
      ? theme.palette.primary.main
      : theme.palette.secondary.main
  }`,
  padding: '9.5px',
  backgroundColor: 'transparent',
  color:
    styleType === 'blueOutline'
      ? theme.palette.primary.main
      : theme.palette.secondary.main,
  '&.active': {
    transition: '0s 0.2s',
    backgroundColor:
      styleType === 'blueOutline'
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    '.hover': {
      color:
        styleType === 'blueOutline'
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
      '&:before': {
        borderRadius: 0,
        backgroundColor:
          styleType === 'blueOutline'
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
        transform: 'scale(1.5)',
      },
    },
    '.animation': {
      transform: 'translateY(-192%)',
    },
  },
  // pc才做hover
  [theme.breakpoints.up('lg')]: {
    '.hover': {
      color:
        styleType === 'blueOutline'
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
    },
    '&:hover, &.active': {
      transition: '0s 0.2s',
      backgroundColor:
        styleType === 'blueOutline'
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
      '.hover': {
        '&:before': {
          borderRadius: 0,
          backgroundColor:
            styleType === 'blueOutline'
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
          transform: 'scale(1.5)',
        },
      },
      '.animation': {
        transform: 'translateY(-192%)',
      },
    },
  },
}));
const StyledGroup = styled(Box)(({ theme }) => ({
  transition: '.4s',
  transform: 'translateY(0)',
}));

const StyledUnHover = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'label',
})<{ label?: string }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
}));

const StyledHover = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'styleType',
})<{ styleType?: ButtonStyleType }>(({ theme, styleType }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  position: 'absolute',
  transform: 'translateY(192%)',
  transformOrigin: 'bottom',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  '&:before': {
    backgroundColor:
      styleType === 'blueOutline'
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    borderRadius: '50%',
    transition: '.4s',
    transform: 'scale(1) translateY(10px)',
    zIndex: '-1',
    position: 'absolute',
    content: '""',
    width: '150%',
    height: '350%',
    top: '-44%',
    left: '-24%',
  },
}));

export { StyledWrapper, StyledGroup, StyledUnHover, StyledHover };

import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  StyledHeaderLogo,
  StyledHeader,
  StyledMenuTitle,
  StyledMenuWrapper,
  StyledMenuChildrenWrapper,
  StyledSingleChildrenGroup,
  StyledChildrenItem,
  StyledBar,
  StyledMenuContent,
  // mobile
  StyledMobileMenuWrapper,
  StyledMobileMenuTitle,
  StyledAccordionWrapper,
  StyledMobileMenuItem,
} from './styles';
import MobileHambergerIcon from '../../Icon/MobileHambergerIcon';
import { Link } from 'gatsby';
import { menuList } from '../../../data/menu-data';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TransitionContext } from '../../Context/TransitionContext';
import { navigate } from '@reach/router';
import SlideUpIcon from '../../animation/SlideUpIcon';
import { Helmet } from 'react-helmet';

const Header = () => {
  const theme = useTheme();
  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [active, setActive] = useState(false);
  const { isPageTransition, updatePageTransition } =
    useContext(TransitionContext);

  const handleClickMobileMenu = () => {
    setActive((prev) => !prev);
  };

  // 延遲1秒先做過場動畫
  const handleLinkClick = (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    updatePageTransition(true);
    // setExpanded(false);
    setActive(false);
    setTimeout(() => {
      navigate(event.target.href || '/');
    }, 1000);
  };

  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleChange =
    (panelId: number) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panelId : false);
    };

  const handleMouseEnter = () => {
    if (!isLgScreen) return;
    setActive(true);
  };

  const handleMouseLeave = () => {
    if (!isLgScreen) return;
    setActive(false);
  };

  return (
    <StyledHeader
      active={active}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledBar>
        <StyledHeaderLogo>
          <Link to="/" onClick={handleLinkClick}>
            <img
              src="/images/navbar-logo.webp"
              width="155"
              height="38"
              alt="logo"
            />
          </Link>
        </StyledHeaderLogo>
        {!isLgScreen && (
          <IconButton sx={{ padding: 0 }} onClick={handleClickMobileMenu}>
            <SlideUpIcon
              sx={{ padding: '0' }}
              active={active}
              styleType="blueOutline"
              icon={<MobileHambergerIcon />}
            />
          </IconButton>
        )}
        {isLgScreen && (
          <StyledMenuWrapper>
            {menuList.map((menu) => (
              <Box key={menu.id}>
                <StyledMenuTitle>{menu.name}</StyledMenuTitle>
              </Box>
            ))}
          </StyledMenuWrapper>
        )}
      </StyledBar>
      <StyledMenuContent active={active}>
        {/* 手機版選單 */}
        {!isLgScreen && (
          <StyledMobileMenuWrapper>
            {menuList.map((menu) => (
              <StyledAccordionWrapper key={menu.id}>
                <Accordion
                  disableGutters
                  expanded={expanded === menu.id}
                  onChange={handleChange(menu.id)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <StyledMobileMenuTitle>{menu.name}</StyledMobileMenuTitle>
                  </AccordionSummary>
                  <AccordionDetails>
                    {menu.children.map((children) => (
                      <StyledMobileMenuItem key={children.id}>
                        <Link to={children.path} onClick={handleLinkClick}>
                          {children.name.replace('\n', '')}
                        </Link>
                      </StyledMobileMenuItem>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </StyledAccordionWrapper>
            ))}
          </StyledMobileMenuWrapper>
        )}
        {isLgScreen && (
          <StyledMenuChildrenWrapper>
            {menuList.map((menu) => (
              <StyledSingleChildrenGroup key={menu.id}>
                {menu.children.map((children) => (
                  <StyledChildrenItem
                    key={children.id}
                    isTextBreak={children.id === 13}
                  >
                    <Link to={children.path} onClick={handleLinkClick}>
                      {children.name}
                    </Link>
                  </StyledChildrenItem>
                ))}
              </StyledSingleChildrenGroup>
            ))}
          </StyledMenuChildrenWrapper>
        )}
      </StyledMenuContent>
    </StyledHeader>
  );
};

export default Header;

import React, { useState } from 'react';
import { animated, useSpring, useInView } from '@react-spring/web';

const PageFinishedFadeIn = () => {
  const [finished, setFinished] = useState(false);
  const [ref, inView] = useInView();
  const styles = useSpring({
    backgroundColor: inView ? 'transparent' : 'black',
    // delay: 1000,
    config: {
      duration: 500,
    },
    onRest: () => {
      setFinished(true);
    },
  });

  return (
    <animated.div
      ref={ref}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        zIndex: finished ? -1 : 100,
        ...styles,
      }}
    />
  );
};

export default PageFinishedFadeIn;

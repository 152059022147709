export const menuList = [
  {
    id: 1,
    name: "產品介紹",
    children: [
      {
        id: 2,
        path: "/carbon-filters", // e3
        name: "e3極緻活性碳濾心",
      },
      {
        id: 3,
        path: "/uv-c-led", // uvcled
        name: "UV-C LED \n紫外線滅菌艙",
      },
      {
        id: 4,
        path: "/amway-healthy-home", //app
        name: "安麗純淨居家App",
      },
    ],
  },
  {
    id: 5,
    name: "NSF認證",
    children: [
      {
        id: 6,
        path: "/about-nsf", // nsf-know
        name: "瞭解NSF認證",
      },
      {
        id: 7,
        path: "/nsf-certificate", // nsf-all
        name: "NSF全機認證",
      },
      {
        id: 8,
        path: "/nsf-401-certificate", // nsf-401
        name: "NSF401認證",
      },
      {
        id: 9,
        path: "/nsf-test-report", // nsf-report
        name: "NSF測試報告",
      },
    ],
  },
  {
    id: 10,
    name: "關於我們",
    children: [
      {
        id: 11,
        path: "/espring-brand-story", // brand-story
        name: "品牌故事",
      },
      {
        id: 12,
        path: "/sustainability", // environmental-protection
        name: "益之源環保",
      },
      {
        id: 13,
        path: "/services", // service
        name: "益之源優質服務及\n產品註冊",
      },
    ],
  },
  {
    id: 14,
    name: "常見問題",
    children: [
      {
        id: 15,
        path: "/espring-faq", // qa-landing
        name: "問與答",
      },
    ],
  },
];

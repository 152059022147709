// gatsby-browser.js

import React from "react";
import { HelmetProvider } from "react-helmet-async";
import Layout from "./src/components/Layout";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "./src/createEmotionCache";
import { StylesProvider, createGenerateClassName } from "@mui/styles";
import "@fontsource/noto-sans-tc";
import { TransitionProvider } from "./src/components/Context/TransitionContext";
// Create a custom emotion cache
const cache = createEmotionCache();
const generateClassName = createGenerateClassName({
  productionPrefix: "mui",
  disableGlobal: true,
});

export const wrapRootElement = ({ element }) => {
  return (
    <CacheProvider value={cache}>
      <HelmetProvider>
        <StylesProvider generateClassName={generateClassName}>
          <TransitionProvider>{element}</TransitionProvider>
        </StylesProvider>
      </HelmetProvider>
    </CacheProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

import React, { useContext } from 'react';
import { StyledTransition } from './styles';
import { TransitionContext } from '../../Context/TransitionContext';

const TransitionPage = () => {
  const { isPageTransition } = useContext(TransitionContext);
  return <StyledTransition className={isPageTransition ? 'transition' : ''} />;
};

export default TransitionPage;

import React from 'react';

const YtIcon = () => {
  return (
    <svg
      width="44"
      height="33"
      viewBox="0 0 44 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="icon-youtube"
        d="M43.6235 8.84753C43.3551 6.04028 42.7731 2.94008 40.6335 1.30884C38.9739 0.0443101 36.7951 -0.00204767 34.7613 5.98803e-05C30.4605 0.00427497 26.1597 0.00847668 21.8589 0.0126918C17.7227 0.0169069 13.5865 0.0211343 9.45029 0.0253494C7.72213 0.0253494 6.04294 -0.117963 4.43822 0.685012C3.05883 1.37629 1.98122 2.68928 1.33463 4.1498C0.433321 6.18359 0.24324 8.48502 0.135475 10.7338C-0.0643799 14.8266 -0.0428136 18.9321 0.198188 23.0208C0.372571 26.0072 0.817319 29.3055 2.96282 31.2086C4.86536 32.8946 7.54968 32.9768 10.0146 32.9789C17.8442 32.9853 25.6738 32.9916 33.5034 33C34.5066 33 35.5548 32.981 36.5776 32.8609C38.5919 32.627 40.5101 32.0073 41.8032 30.4035C43.1082 28.787 43.4432 26.534 43.6431 24.4033C44.1251 19.2356 44.1192 14.0131 43.6235 8.84753ZM17.3308 23.7479V9.25216L29.0028 16.5L17.3308 23.7479Z"
        fill="#00A9E0"
      />
    </svg>
  );
};

export default YtIcon;

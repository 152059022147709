import React from 'react';

const MobileHambergerIcon = () => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5604 22H34.4404"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5604 14.96H34.4404"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5604 29.04H34.4404"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* <circle cx="22.7051" cy="22" r="21" stroke="currentColor" strokeWidth="2" /> */}
    </svg>
  );
};

export default MobileHambergerIcon;

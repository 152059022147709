import React, { useState } from 'react';

export const TransitionContext = React.createContext({
  isPageTransition: false,
  updatePageTransition: (newValue: boolean) => {},
});

type Props = {
  children: React.ReactElement;
};

export const TransitionProvider = ({ children }: Props) => {
  const [isPageTransition, setIsPageTransition] = React.useState(false);

  const updatePageTransition = (newValue: boolean) => {
    setIsPageTransition(newValue);
  };

  return (
    <TransitionContext.Provider
      value={{ isPageTransition, updatePageTransition }}
    >
      {children}
    </TransitionContext.Provider>
  );
};

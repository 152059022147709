import React from 'react';

const VectorIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M39.5725 20C39.5725 8.97345 30.695 0 19.7863 0C8.87755 0 0 8.97345 0 20C0 31.0266 8.87755 40 19.7863 40C30.695 40 39.5725 31.0266 39.5725 20ZM28.6638 15.1327C28.6638 16.6549 27.6482 18.0354 25.7922 18.9911C24.1813 19.8407 22.0626 20.3009 19.8213 20.3009C17.58 20.3009 15.5138 19.8407 13.9029 19.0089C14.4632 22 15.0936 25.1504 15.3913 25.9115L15.3212 25.9469H15.4263C15.4263 26.708 17.2998 27.8584 20.119 27.8584C22.9381 27.8584 24.8116 26.708 24.8116 25.9469H27.0354C27.0354 28.3186 24.0587 30.1239 20.119 30.1239C16.6345 30.1239 13.9029 28.7257 13.3251 26.7611C12.6947 25.1504 11.2939 17.1327 11.0838 15.9823C11.0138 15.6991 10.9788 15.4159 10.9788 15.1327C10.9788 13.6106 11.9943 12.2301 13.8329 11.2743C15.4438 10.4248 17.5625 9.9646 19.8038 9.9646C22.0451 9.9646 24.1638 10.4248 25.7747 11.2743C27.6132 12.2478 28.6463 13.6106 28.6463 15.1327M26.4225 15.1327C26.4225 13.7522 23.5859 12.2124 19.8038 12.2124C16.0216 12.2124 13.185 13.7522 13.185 15.1327C13.185 16.5133 16.0216 18.0531 19.8038 18.0531C23.5859 18.0531 26.4225 16.5133 26.4225 15.1327Z"
        fill="#00A7E1"
      />
    </svg>
  );
};

export default VectorIcon;

import { Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const StyledFooterLogo = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '50%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '25%',
  },
}));

const StyledFooter = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
}));

const StyledFooterLayer = styled(Box)(({ theme }) => ({
  maxWidth: '1440px',
  margin: 'auto',
  padding: '40px 9px 50px 9px',
  [theme.breakpoints.up('sm')]: {
    padding: '53px 21px 0',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '53px 80px 0',
  },
}));

const StyledMenuGroup = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '50%',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    gap: '65px',
    width: 'auto',
  },
}));

const StyledMenuList = styled(Box)(({ theme }) => ({
  marginTop: '37px',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  height: '450px',
  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    gap: '50px',
    height: 'auto',
  },
}));

const StyledSingleMenuGroup = styled(Box)(({ theme }) => ({
  minHeight: '212px',
  '&:nth-of-type(3), &:nth-of-type(4)': {
    marginLeft: '24px',
  },
  [theme.breakpoints.up('lg')]: {
    '&:nth-of-type(3), &:nth-of-type(4)': {
      marginLeft: '0',
    },
  },
}));

const StyledMenuTitle = styled('h4')(({ theme }) => ({
  margin: 0,
  color: theme.palette.primary.main,
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '20px',
}));

const StyledMenuWrapper = styled(Box)(({ theme }) => ({
  margin: '18px 0 40px 0',
  minHeight: '107px',
  [theme.breakpoints.up('lg')]: {
    margin: '18px 0 20px 0',
  },
}));

const StyledMenuItem = styled('h5')(({ theme }) => ({
  width: 'fit-content',
  margin: '13px 0 0 0',
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0.32px',
  lineHeight: '27px',
  color: theme.palette.grey[900],
  a: {
    color: 'currentColor',
    textDecoration: 'none',
    transition: '.2s',
    whiteSpace: 'break-spaces',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
}));

const StyledExternalLink = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '46px',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'space-between',
  },
}));

const StyledRWDGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '46px',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledCopyrightWrapper = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.main}`,
  // padding: '16px 9px 20px 9px',
  backgroundColor: theme.palette.grey[300],
  padding: '18px 0 12px 0',
  marginTop: '20px',
}));

const StyledCompany = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '20px',
  color: theme.palette.grey[900],
}));

const StyledCopyright = styled(Typography)(({ theme }) => ({
  marginTop: '5px',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '27px',
  color: theme.palette.grey[900],
  letterSpacing: '0.32px',
  a: {
    marginLeft: '6px',
  },
}));

const StyledLogoAndMenuWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}));

export {
  StyledFooterLogo,
  StyledFooter,
  StyledFooterLayer,
  StyledMenuGroup,
  StyledMenuList,
  StyledSingleMenuGroup,
  StyledMenuTitle,
  StyledMenuWrapper,
  StyledMenuItem,
  StyledExternalLink,
  StyledRWDGroup,
  StyledCopyrightWrapper,
  StyledCompany,
  StyledCopyright,
  StyledLogoAndMenuWrapper,
};

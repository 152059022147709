import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const StyledLayout = styled(Box)(() => ({
  position: 'relative',
  margin: 'auto',
}));

// layout robot
const StyledRobot = styled('a')(({ theme }) => ({
  cursor: 'pointer',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: '15px',
  bottom: '30px',
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  backgroundColor: theme.palette.secondary.main,
  zIndex: 5,
  img: {
    width: '30px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '86px',
    height: '86px',
    img: {
      width: '53px',
    },
  },
}));

const StyledHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  maxWidth: '1440px',
  position: 'fixed',
  zIndex: 10,
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'space-between',
  top: '12px',
  left: 0,
  right: 0,
  margin: 'auto',
  // transition: '.2s',
  // height: '56px',
  borderRadius: '32px',
  width: 'calc(100% - 18px)',
  backdropFilter: 'blur(4px)',
  backgroundColor: alpha(theme.palette.grey[400], 0.8),
  [theme.breakpoints.up('lg')]: {
    justifyContent: 'flex-start',
    maxWidth: 'calc(1440px - 160px)', // margin 80*2
  },
}));

const StyledBar = styled(Box)(({ theme }) => ({
  padding: '10px 12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledMenuContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  height: active ? 'calc(100vh - 200px)' : '0',
  display: 'flex',
  flexDirection: 'column',
  transition: '.2s',
}));

const StyledHeaderLogo = styled('h1')(({ theme }) => ({
  margin: 0,
  a: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  img: {
    verticalAlign: 'middle',
  },
  [theme.breakpoints.up('lg')]: {
    width: '20%',
  },
}));
const StyledMenuWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  gap: '180px',
}));

const StyledMenuTitle = styled('h2')(({ theme }) => ({
  margin: 0,
  fontSize: '20px',
  fontWeight: 700,
  color: theme.palette.secondary.dark,
}));

export {
  StyledLayout,
  StyledRobot,
  StyledHeader,
  StyledHeaderLogo,
  StyledBar,
  StyledMenuContent,
  StyledMenuWrapper,
  StyledMenuTitle,
};

import React, { ReactNode, useContext, useEffect, useState } from 'react';
import Footer from './Footer';
import { StyledLayout, StyledRobot } from './styles';
import Header from './Header';
import TransitionPage from './TransitionPage';
import { useLocation } from '@reach/router';
import { TransitionContext } from '../Context/TransitionContext';
import PageFinishedFadeIn from '../animation/PageFinishedFadeIn';

type Props = {
  children: ReactNode;
};

const Layout = ({ children }: Props) => {
  const { isPageTransition, updatePageTransition } =
    useContext(TransitionContext);

  const location = useLocation();

  const handleClick = (path: string) => {
    window.open(path, '_blank', 'noopener,noreferrer');
  };
  // 確保換頁時才更新關閉isPageTransition並執行PageFinishedFadeIn
  useEffect(() => {
    updatePageTransition(false);
  }, [location]);

  return (
    <StyledLayout>
      {/* TransitionPage結束後接著做PageFinishedFadeIn */}
      <TransitionPage />
      {!isPageTransition && <PageFinishedFadeIn />}
      <Header />
      {children}

      <Footer />
      <StyledRobot
        onClick={() =>
          handleClick(
            'https://care.amway.com.tw/live800/chatClient/chatbox.jsp?companyID=9058&configID=3&enterurl=Hybris&lan=zh_TW'
          )
        }
      >
        <img src="/images/robot.webp" alt="智能小安" title="智能小安" />
      </StyledRobot>
    </StyledLayout>
  );
};

export default Layout;

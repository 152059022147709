import React from 'react';

const FbIcon = () => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.0357 0H3.96429C2.91289 0 1.90456 0.406377 1.16111 1.12973C0.417665 1.85309 0 2.83417 0 3.85714L0 32.1429C0 33.1658 0.417665 34.1469 1.16111 34.8703C1.90456 35.5936 2.91289 36 3.96429 36H15.2997V23.7608H10.0965V18H15.2997V13.6093C15.2997 8.61509 18.3555 5.85643 23.0358 5.85643C25.2773 5.85643 27.6212 6.24536 27.6212 6.24536V11.1471H25.0386C22.494 11.1471 21.7003 12.6836 21.7003 14.2594V18H27.3808L26.4723 23.7608H21.7003V36H33.0357C34.0871 36 35.0954 35.5936 35.8389 34.8703C36.5823 34.1469 37 33.1658 37 32.1429V3.85714C37 2.83417 36.5823 1.85309 35.8389 1.12973C35.0954 0.406377 34.0871 0 33.0357 0Z"
        fill="#00A9E0"
      />
    </svg>
  );
};

export default FbIcon;

import { Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { ButtonStyleType } from '../../../types/button';

const StyledTransition = styled(Box)(({ theme }) => ({
  position: 'fixed',
  zIndex: 100,
  top: 0,
  left: 0,
  width: '150%',
  height: '100%',
  transform: 'skewX(-8deg) translateX(-120%)',
  // transform: 'skewY(-8deg) translateY(-100%)',
  backgroundColor: 'black',
  '&.transition': {
    transition: '.6s ease',
    transform: 'skewX(-8deg) translateX(-10%)',
    // transform: 'skewY(-8deg) translateY(0)',
  }
}));

export { 
  StyledTransition,
};

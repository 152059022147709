export const PRIMARY_MAIN = "#00A9E0";
export const PRIMARY_LIGHT = "#65D9FF";
export const PRIMARY_DARK = "#004976";
export const PRIMARY_BASIC = "#0077C8";

export const SECONDARY_MAIN = "#ffffff";
export const SECONDARY_DARK = "#000000";
export const SECONDARY_LIGHT = "#DFEEF7";

export const GREY100 = "#F3F7F9";
export const GREY200 = "#EEEEEE";
export const GREY300 = "#EFF0F2";
export const GREY400 = "#E5EDF1";
export const GREY500 = "#ECF2F5";
export const GREY600 = "#E5EBEE";
export const GREY700 = "#D7E0E6";
export const GREY800 = "#C1C6C8";
export const GREY900 = "#5F6468";
export const GREYA100 = "#888F95";
export const GREYA200 = "#ABB6BC";
export const GREYA400 = "#414141";
export const GREYA700 = "#F1F1F1";

export const TEXT_PRIMARY = "#5F6468";
export const TEXT_SECONDARY = "#333";